.container-info{
   margin-bottom: 3rem;
   margin-top: 10rem;
}

.img-banner{
    height: 14rem;
    margin-top: 13rem;
    border-radius: 1rem;
    width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
}

.flex-center{
    display: flex;
    justify-content: center;
}

.blue-text{
    color:rgb(1 74 173)
}

.blue-text-inside{
    color:rgb(1 74 173)
}

.red-text{
    color: rgb(192 12 83)
}

.paragraph{
    margin-top: 4rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.paragraph p{
    font-size: 1.5rem;
}

.bold{
    font-weight: bold;
}

.gap-top {
    margin-top: 5rem;
}

.license-h {
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {

    .img-banner{
        width: 95%;
        margin-left: 0rem;
        margin-right: 0rem;
        margin-top: 10rem;
    }

    .blue-text {
        font-size: 1.3rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .paragraph {
        margin-left: 1rem;
    }

    .paragraph p {
        font-size: 1rem;
    }
}
