.Snowflake {
	display: inline-block;
	color: #db1790;
	opacity: 0;
	font-size: 120px;
	margin: 0;
	padding: 0;
	animation: fall 16s linear infinite;
}

.lotusfall {
	height: 0;
}

@keyframes fall {
	0% {
		opacity: 0;
	}
	3% {
		opacity: 0.9;
	}

	90% {
		opacity: 0.9;
	}
	100% {
		transform: translate(0, 97vh);
		opacity: 0;
	}
}